import React, { useState } from "react";
import { Button, Modal, Upload, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../conf/config";
import { useUser } from "../UserContext";
import { openPDFInNewTab } from "./GenerateFilePopup";
import { parseWarningsAndErrors } from "../conf/config";

const FileUploadPop = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);

    const { fetchWithAuth, addNewLog } = useUser();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (fileList.length === 0) {
            notification.error({
                message: "Error",
                description: "Please select at least one file before uploading.",
            });
            return;
        }

        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append("files", file.originFileObj);
        });
	formData.append("requestType", "inno_invoices")

        fetchWithAuth(`${BASE_URL}/api/parse/`, {
            method: "POST",
            body: formData,
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Failed to parse the documents.");
                }
                return res.json();
            })
            .then((data) => {

                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                openPDFInNewTab(data);
                addNewLog(`upload files`, parsedWarnings, parsedErrors);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors });
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings });
                }


            })
            .catch((error) => {
                console.error("Error:", error);
                notification.error({
                    message: "Error",
                    description: "Failed to parse the documents. Please try again later.",
                });
            });

        setFileList([]); // Clear file list after processing
        setIsModalVisible(false); // Close modal
    };

    const handleCancel = () => {
        setFileList([]); // Clear file list
        setIsModalVisible(false); // Close modal
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList); // Update file list
    };

    return (
        <div>
            <Button type="primary" className="header-button" onClick={showModal}>
                Check Innocoso invoices
            </Button>
            <Modal
                title="Upload Files"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Upload"
                cancelText="Cancel"
                width={800}
                style={{ minHeight: "40vh", top: "30vh" }}
            >
                <div style={{ padding: "20px" }}>
                    <Upload
                        multiple
                        fileList={fileList}
                        onChange={handleChange}
                        beforeUpload={() => false} // Prevents auto-upload
                    >
                        <Button icon={<UploadOutlined />}>Select Files</Button>
                    </Upload>
                </div>
            </Modal>
        </div>
    );
};

export default FileUploadPop;
