// src/contexts/OrderContext.js

import React, { createContext, useState, useEffect, useRef } from "react";
import { notification } from 'antd';
import { useUser } from "./UserContext";
import { BASE_URL, parseWarningsAndErrors, parseDate, getCookie } from "./conf/config";


export const OrderContext = createContext();

// This context is used for ordertable persistence, including searching status, table status, etc.
export const OrderProvider = ({ children }) => {

    const { orders, setOrders, refreshOrders, addNewLog, fetchWithAuth, userGroups } = useUser();

    const [pinOrders, setPinOrders] = useState([]);
    // if searchedResult exist, the table only shows searchedResult
    const [searchedResult, setSearchedResult] = useState([]);
    // cols is the columns of the table
    const [cols, setCols] = useState([]);
    // options for Select of search fields
    const [options, setOptions] = useState([]);
    // options for Select of table fields
    const [tableSelectOptions, setTableSelectOptions] = useState([]);
    // selected keys for fields of table
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // selected keys for fields of search
    const [searchFields, setSearchFields] = useState([]);
    // selected keys for fields of table
    const [colFields, setColFields] = useState([]);
    // current page index
    const [current, setCurrent] = useState(1);
    // page size
    const [pageSize, setPageSize] = useState(20);
    // dropDownSearchItem is the options for dropdown search
    const [dropDownSearchItem, setDropDownSearchItem] = useState([]);
    // isInitialLoading is used to control the loading state of the table
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    // mapping from backend field names to frontend field names, make human readable
    const fieldMapping = {
        id: "ID",
        status: "Status",
        customer: "Customer",
        order_type: "PO/SA",
        order_ID: "Order ID",
        position_ID: "Pos.",
        CPN: "CPN",
        shipping_date_planned: "ETD planned",
        order_date: "Order date",
        invoice_ID: "Invoice",
        shipping_ID: "Shipping ID",
        tracking_ID: "Tracking",
        shipping_CO: "Shipping Co.",
        shipping_method: "Ship. Meth.",
        HS: "HS",
        article_ID: "Article ID",
        quantity: "Quantity",
        unit_price: "Unit price",
        total_net: "Total net",
        incoterm: "Incoterm",
        fob_point: "FOB-point",
        inno_ID: "Innoc. ID",
        ETA: "ETA",
        shipping_date_real: "ETD eff.",
        partial_delivered_quantity: "Partial quant.",
        invoice_date: "Inv. date",
        invoice_due: "Inv. due date",
        customer_paid_date: "Paid",
        stwxe_invoice_ID: "STWXE inv.",
        inno_paid_date: "STWXE paid",
        inv_sig: "Inventory sign.",
        change_date: "Change date",
        cancellation_ID: "Cancel ID",
        cancellation_date: "Cancel date",
        ctn: "CTNs",
        gross_weight: "Weight gr./kg",
        net_weight: "Weight net/kg"
    };

    // hard code status options 
    const statusOptions = [
        { label: 'fresh', value: 'fresh' },
        { label: 'invoice cancelled', value: 'invoice_cancelled' },
        { label: 'shipped', value: 'shipped' },
        { label: 'stwxe', value: 'stwxe' },
        { label: 'cancelled', value: 'cancelled' },
        { label: 'paid', value: 'paid' },
        { label: 'all', value: '' },
    ];

    const defaultSearchItem = ["id", "status", "customer", "order_type", "order_ID"];

    const defaultColumnFields = userGroups.includes('admin') ? ["id", "status", "customer", "order_type", "order_ID", "position_ID", "CPN", "shipping_date_planned", "order_date", "shipping_ID", "quantity", "fob_point", "incoterm"] : ['id', 'customer', 'invoice_date', 'invoice_due', 'shipping_date_planned', 'shipping_date_real', 'status'];

    const GenerateFileRef = useRef();

    // update cols if colFields changed
    const setupData = () => {
        if (orders.length > 0) {
            const firstRecord = orders[0];
            if (typeof firstRecord !== 'object') {
                throw new TypeError('Unsupported response type');
            } else {
                setSearchFields(defaultSearchItem.map(item => ({
                    label: fieldMapping[item] || item,
                    value: item
                })));
                setColFields(defaultColumnFields.map(item => ({
                    label: fieldMapping[item] || item,
                    value: item
                })));
                const options = Object.keys(firstRecord).map(key => ({
                    value: key,
                    label: fieldMapping[key] || key
                }));
                const searchItems = Object.keys(firstRecord).map((key, index) => ({
                    label: fieldMapping[key] || key,
                    key: index,
                    value: key
                }));
                setOptions(options);
                setTableSelectOptions(firstRecord);
                setDropDownSearchItem(searchItems);
            }
            setIsInitialLoading(false);
        }
    };

    // update searchedResult if orders changed
    useEffect(() => {
        if (searchedResult.length > 0) {
            const updatedSearchedResult = searchedResult.map(searchItem => {
                const matchingOrder = orders.find(order => order.id === searchItem.id);
                if (matchingOrder) {
                    // replace the matching order with the updated order
                    return { ...matchingOrder };
                }
                return searchItem;
            });
            setSearchedResult(updatedSearchedResult);
        }
    }, [orders]);

    // only run once when isInitialLoading is true
    useEffect(() => {
        if (isInitialLoading) {
            setupData();
        }
    }, [orders]);

    // reorder the data if it is pinned
    const handlePinClick = (record) => {
        let newPinOrders = [];
        const exist = pinOrders.some(order => order.id === record.id);
        if (exist) {
            newPinOrders = pinOrders.filter(order => order.id !== record.id);
        } else {
            record["isTop"] = true;
            newPinOrders = [...pinOrders, record];
        }
        setPinOrders(newPinOrders);
    };

    // enable table sortor function
    const getSorter = (field) => (a, b, sortOrder) => {
        const isDateField = field.toLowerCase().includes("date");

        if (sortOrder === 'ascend') {
            if (a.isTop !== b.isTop) {
                return a.isTop ? -1 : 1;
            }
        } else if (sortOrder === 'descend') {
            if (a.isTop !== b.isTop) {
                return a.isTop ? 1 : -1;
            }
        }

        // order date data
        if (isDateField) {
            const dateA = parseDate(a[field]);
            const dateB = parseDate(b[field]);
            return dateA - dateB;
        }

        // if the string can convert to number, sort by number
        const numA = parseFloat(a[field]);
        const numB = parseFloat(b[field]);
        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
        }

        // last sort by string
        return a[field].localeCompare(b[field]);
    };


    return (
        <OrderContext.Provider
            value={{
                orders, setOrders, refreshOrders, addNewLog, fetchWithAuth,
                pinOrders, setPinOrders,
                searchedResult, setSearchedResult,
                cols, setCols,
                options, setOptions,
                tableSelectOptions, setTableSelectOptions,
                selectedRowKeys, setSelectedRowKeys,
                searchFields, setSearchFields,
                colFields, setColFields,
                current, setCurrent,
                pageSize, setPageSize,
                dropDownSearchItem, setDropDownSearchItem,
                statusOptions,
                setupData, handlePinClick, getSorter,
                GenerateFileRef,
                fieldMapping,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
