import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { Form, Input, Button, notification, Modal, message, Select } from 'antd';
import { BASE_URL, getCookie, parseWarningsAndErrors } from "../conf/config";
import './GenerateFilePopup.css';
import { checkListOfDictSanity } from "../utils/utils";
import { useUser } from "../UserContext";
import { OrderContext } from "../OrdersContext";
import { useContext } from "react";

// Function to open PDF URLs in a new tab and initiate download for each file
const openPDFInNewTab = async (data) => {

    try {
        // url list
        let urls = data["urls"]; // Extracts the list of URLs from the response data
        if (!urls || urls.length === 0) {
            return; // if urls not exist, e.g. edit action
        }
        for (let i = 0; i < urls.length; i++) {
            // window.open(urls[i], '_blank');
            // download the file
            // get the file name
            const filename = urls[i].split("/").pop();
            // Open and download each PDF file
            await openPdfAndDownload(urls[i], filename);

        }
    } catch (error) {
        // get error text and notify
        notification.error({
            message: 'Error',
            description: 'Error Opening PDF'
        })
    }
}

// Helper function to download files from a given URL
async function openPdfAndDownload(url, fallbackFilename = 'downloaded_file') {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getCookie('access_token')}`,
                Accept: "*/*",
            },
        });

        if (!response.ok) {
            notification.error({
                message: 'Error',
                description: 'Error generating file, Network response was not ok',
            });
            throw new Error('Network response was not ok');
        }

        const contentType = response.headers.get('Content-Type');
        const contentDisposition = response.headers.get('Content-Disposition');

        // Extract filename from Content-Disposition header if available
        let filename = fallbackFilename;
        if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition
                .split('filename=')[1]
                .replace(/"/g, '') // Remove quotes
                .trim();
        }

        // Validate supported file types if necessary
        const supportedTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ];
        if (!supportedTypes.includes(contentType)) {
            notification.error({
                message: 'Error',
                description: `Unexpected file type: ${contentType}`,
            });
            throw new Error(`Unexpected file type: ${contentType}`);
        }

        // Download the file
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    } catch (error) {
        console.error('Error downloading the file:', error);
        notification.error({
            message: 'Download Failed',
            description: 'An error occurred while downloading the file. Please try again.',
        });
    }
}


// Function to sort fields alphabetically by their labels
function sortColFieldsAlphabetically(colFields) {
    const newColFields = [...colFields];

    newColFields.sort((a, b) => {
        let strA = String(a.label).toLowerCase();
        let strB = String(b.label).toLowerCase();

        return strA.localeCompare(strB);
    });

    return newColFields;
}

// Main component for generating different types of files
const GenerateFilePopup = React.forwardRef((props, ref) => {
    const dialogRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    // state to store the transferred order data
    const [invoiceOrders, setInvoiceOrders] = useState([]);
    // generate file type
    const [fileType, setFileType] = useState("");

    const { options, orders, fieldMapping } = useContext(OrderContext); // Use context for shared state
    // Custom options for dropdowns
    const [customOptions, setCustomOptions] = useState([]);
    // Column headers for the table
    const [keyHeaders, setKeyHeaders] = useState([]);

    // required fields for different file types
    const required_fields_invoice = ["shipping_ID", "tracking_ID"];
    //const required_fields_china_import = ["shipping_ID", "invoice_ID", "ctn", "gross_weight", "net_weight"];
    const required_fields_china_import = ["invoice_ID"];
    const keyFields = ['customer', 'order_ID', 'position_ID', 'CPN', 'quantity', 'shipping_ID', 'tracking_ID', 'total_net', 'unit_price'];
//govers the order the fields
    const chinaImportKeyFields = ['customer', 'order_ID', 'position_ID', 'CPN', 'quantity', 'invoice_ID', 'ctn', 'gross_weight', 'net_weight', 'palettes', 'shipping_ID', 'tracking_ID'];
    const creditKeyFields = ['customer', 'order_ID', 'position_ID', 'CPN', 'quantity', 'shipping_ID', 'tracking_ID', 'total_net', 'unit_price', 'note', 'credit_amount'];
    let extraFields = ["price_agreement"]; // extra fields uncondionally included
    // if the sended order data is not empty, set the invoiceOrders state
    useEffect(() => {
        //const selectedKeyFields = fileType === "china_import" ? chinaImportKeyFields : keyFields;
	    let selectedKeyFields;

	    if (fileType === "china_import") {
       		selectedKeyFields = chinaImportKeyFields;
	    } else if (fileType === "credit_note") {
        	selectedKeyFields = creditKeyFields;
	    } else {
        	selectedKeyFields = keyFields;
	    };
        const allFields = Object.keys(invoiceOrders[0] || {});
        const otherFields = allFields.filter(field => !selectedKeyFields.includes(field));
        // prevent duplicate
        const newColFields = Array.from(
            new Set([...selectedKeyFields, ...otherFields, ...extraFields])
        );

        setKeyHeaders(newColFields.map(field => ({ value: field, label: fieldMapping[field] || field })));
        console.log("setKeyHeaders", newColFields.map(field => ({ value: field, label: fieldMapping[field] || field })));

    }, [invoiceOrders])

    useEffect(() => {
        // set options for dropdowns
        if (fileType === "china_import") {
            extraFields.unshift("palettes");
            // add extra field palettes to invoiceOrders
            if (invoiceOrders.length > 0) {
                const newInvoiceOrders = invoiceOrders.map(order => {
                    const newOrder = { ...order };
                    extraFields.forEach(field => {
                        newOrder[field] = '';
                    });
                    return newOrder;
                })
                console.log("newInvoiceOrders", newInvoiceOrders);
                setInvoiceOrders(newInvoiceOrders);
            }
            if (options.length > 0) {
                const extraOptions = extraFields.map(field => ({ value: field, label: fieldMapping[field] || field }));
                const newOptions = [...options, ...extraOptions];
                setCustomOptions(newOptions);
            }
        }
        else {
            const extraOptions = extraFields.map(field => ({ value: field, label: fieldMapping[field] || field }));
            const newOptions = [...options, ...extraOptions];
            setCustomOptions(newOptions);
        }
    }, [fileType, options])

    const handleSelectChange = (newValue, index) => {

        const newKeyFields = [...keyHeaders];
        const targetIndex = newKeyFields.findIndex(({ value }) => value === newValue);
        if (targetIndex === -1 || targetIndex === index) {
            // if the selected value is not in the keyFields array or the same as the current value, do nothing
            return;
        }
        // swap the values in the keyFields array
        [newKeyFields[index], newKeyFields[targetIndex]] = [newKeyFields[targetIndex], newKeyFields[index]];
        setKeyHeaders(newKeyFields);
        const updatedValues = invoiceOrders.map((order, rowIndex) => {
            const newValues = {};
            newKeyFields.forEach(({ value: fieldValue }) => {
                newValues[fieldValue] = order ? order[fieldValue] : '';
            });
            return newValues;
        });

        form.setFieldsValue({ orders: updatedValues });
    };

    const renderTableHeaders = () => {
        return (
            <thead>
                <tr>
                    {keyHeaders.map(({ value, label }, index) => (
                        <th key={value} className="table-header-cell">
                            <Select
                                className="custom-selector"
                                dropdownStyle={{ width: 150 }}
                                options={sortColFieldsAlphabetically(customOptions)}
                                onChange={(newValue) => handleSelectChange(newValue, index)}
                                value={fieldMapping[value] || value}
                            />
                        </th>
                    ))}
                    {/* {fileType === "china_import" && (
                        <th className="table-header-cell">palettes</th>
                    )} */}

                </tr>
            </thead>
        );
    };

    const renderTableBody = (fields, fileType, invoiceOrders) => {
        let requiredFields = [];
        if (fileType === "china_import") {
            requiredFields = required_fields_china_import;
        } else if (fileType === "invoice") {
            requiredFields = required_fields_invoice;
        }
        return (
            <tbody>
                {fields.map(({ key, name, ...restField }, rowIndex) => (
                    <tr key={key} className="table-row">
                        {keyHeaders.map(({ value: fieldValue }) => (
                            <td key={fieldValue} className={`table-cell ${requiredFields.includes(fieldValue) ? 'highlight-required' : ''}`}>
                                <Form.Item
                                    {...restField}
                                    name={[name, fieldValue]}
                                    required={requiredFields.includes(fieldValue)}
                                    validateTrigger="onBlur"
                                >
                                    <Input
                                        className="input-field"
                                        value={invoiceOrders[rowIndex] ? invoiceOrders[rowIndex][fieldValue] : ''} // set the value of the input field based on the fieldValue
                                        onChange={(e) => {
                                            const updatedValue = e.target.value;
                                            setInvoiceOrders((prevOrders) => {
                                                const newOrders = [...prevOrders];
                                                if (newOrders[rowIndex]) {
                                                    newOrders[rowIndex] = {
                                                        ...newOrders[rowIndex],
                                                        [fieldValue]: updatedValue
                                                    };
                                                }
                                                return newOrders;
                                            });
                                        }}
                                        style={{
                                            width: `${Math.max((invoiceOrders[rowIndex]?.[fieldValue]?.length || 0) + 4, 3)}ch`, //  adjust according to character length
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    };

    const { refreshOrders, addNewLog, fetchWithAuth } = useUser();

    notification.config({
        top: 50,
        zIndex: 10000, // make sure it will on top of dialog
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            setIsModalOpen(true);
        },
        setInvoiceOrders: (orders) => {
            console.log("setting orders", orders);
            setInvoiceOrders(orders);
            form.setFieldsValue({ orders });
        },
        setFileType: (type) => {
            setFileType(type);
        }
    }));

    const onFinish = (values) => {
        const checkSanity = checkListOfDictSanity(values.orders);
        if (checkSanity !== "sane") {
            notification.warning({ message: 'Warning', description: checkSanity })
            return;
        }
        // check is required fields are filled
        if (fileType === "china_import") {
            const missingFields = required_fields_china_import.filter(field => !values.orders.some(order => order[field]));
            if (missingFields.length > 0) {
                notification.warning({ message: 'Warning', description: `Missing required fields: ${missingFields.join(', ')}` });
                return;
            }
        }
        if (fileType === "invoice") {
            const missingFields = required_fields_invoice.filter(field => !values.orders.some(order => order[field]));
            if (missingFields.length > 0) {
                notification.warning({ message: 'Warning', description: `Missing required fields: ${missingFields.join(', ')}` });
                return;
            }
        }
        // special case for combined stwxe
        let currentFileType = fileType;
        if (fileType.includes("combine")) {
            if (fileType === "combine_stwxe") {
                currentFileType = "stwxe_order";
                values.orders = values.orders.map(order => ({
                    ...order,
                    inno_ID: order.inno_ID === undefined || order.inno_ID === "" ? "combined" : order.inno_ID
                }));
            } else if (fileType === "combine_invoice") {
                currentFileType = "invoice";
                values.orders = values.orders.map(order => ({
                    ...order,
                    invoice_ID: order.invoice_ID === undefined || order.invoice_ID === "" ? "combined" : order.invoice_ID
                }));
            } else if (fileType === "combine_confirmation") {
                currentFileType = "confirmation";
                values.orders = values.orders.map(order => ({
                    ...order,
                    inno_ID: order.inno_ID === undefined || order.inno_ID === "" ? "combined" : order.inno_ID
                }));
            }
        }

        let completeOrders = values.orders.map(order => ({
            ...order,
            price_agreement: order.price_agreement || '', // make sure price_agreement is not undefined
        }));

        if (fileType === "china_import") {
            completeOrders = values.orders.map(order => ({
                ...order,
                net_weight: order.net_weight || '',          // make sure net_weight is not undefined
                gross_weight: order.gross_weight || '',      // make sure gross_weight is not undefined
                ctn: order.ctn || ''                        // make sure ctn is not undefined
            }));

        }

        const msg = { "type": currentFileType, "data": completeOrders };
        const hideLoadingMessage = message.loading('Generating file, please wait...', 0); // show loading message
        fetchWithAuth(`${BASE_URL}/api/orders/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(msg)
        })
            .then(res => res.json())
            .then(data => {
                hideLoadingMessage();  // hide loading message
                const { parsedWarnings, parsedErrors } = parseWarningsAndErrors(data);
                openPDFInNewTab(data);
                addNewLog(`${fileType}`, parsedWarnings, parsedErrors, msg);
                if (parsedErrors.length > 0) {
                    notification.error({ message: 'Error', description: parsedErrors });
                }
                if (parsedWarnings.length > 0) {
                    notification.warning({ message: 'Warning', description: parsedWarnings });
                }
                refreshOrders();
                setIsModalOpen(false);
                if (data["detail"]) {
                    notification.error({ message: 'Error', description: data["detail"] });
                    return;
                }
            })
            .catch(error => {
                hideLoadingMessage();
                notification.error({ message: 'Error', description: 'Error generating file: ' + error.message });
                console.error('Error:', error);
            });
    };

    const closeDialog = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal className="InvoicePopUp-container"
            open={isModalOpen} ref={dialogRef}
            footer={null} title={null}
            closable={false}
            style={{
                top: '7vh',
            }}
            width={"80vw"}>
            <div className="dialog-header">
                <Button type="primary" onClick={closeDialog} className="close-button" danger>Close</Button>
            </div>

            <Form form={form} onFinish={onFinish} layout="vertical" className="popup-form-container">
                <Form.Item className="edit-button-group">
                    <Button className="edit-button" type="primary" htmlType="submit" style={{ marginLeft: "0px" }}>

                        {fileType === "edit" ? "Save edit" : `Generate ${fileType} file`}
                    </Button>
                </Form.Item>
                {(fileType === "china_import" || fileType === "invoice") && (
                    <div className="fields-hint" >
                        <p style={{ textAlign: "start", marginTop: "0px" }}>Fields with red border can not be empty</p>
                    </div>
                )}
                <Form.List name="orders">

                    {(fields) => (
                        <div className="orders-table-container">
                            <table className="orders-table">
                                {renderTableHeaders(fileType, invoiceOrders)}
                                {renderTableBody(fields, fileType, invoiceOrders)}
                            </table>
                        </div>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
});

export default GenerateFilePopup;
export { openPDFInNewTab };
